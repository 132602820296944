<template>
  <div class="crumb">
    <div class="crumb-title">{{ title }}</div>
    <ul class="crumb-menu">
      <li v-for="(item, index) in menu" :key="index">
        <router-link :to="item.url">
          {{ item.text }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Crumb",
  data() {
    return {
      zoujinkaida: {
        menu: [
          { text: "企业简介", url: "/about/qyjj?type=qyjj" },
          { text: "董事长致辞", url: "/about/dszzc?type=dszzc" },
          { text: "组织结构", url: "/about/zzjg?type=zzjg" },
          { text: "企业文化", url: "/about/qywh?type=qywh" },
        ],
        qiyejianjie: { title: "企业简介" },
        dongshizhang: { title: "董事长致辞" },
        zuzhijiegou: { title: "组织结构" },
        qiyewenhua: { title: "企业文化" },
      },
      kaidazixun: {
        menu: [
          { text: "环球看点", url: "/list?type=86GiJyxgPxzp4p7xZ" },
          { text: "聚焦汽开", url: "/list?type=m6ZYzvEfAyAfmk8aL" },
          { text: "凯达动态", url: "/list?type=hkng3zuaD6tgRh8wi" },
          { text: "视频资讯", url: "/list?type=d9myMuK84ALafhXYd" },
          { text: "公告信息", url: "/list?type=uRLG5PtCsXhyDq5tX" },
        ],
        huanqiukandian: { title: "环球看点" },
        jujiaoqikai: { title: "聚焦汽开" },
        kaidadongtai: { title: "凯达动态" },
        shipinzixun: { title: "视频资讯" },
        gonggaoxinxi: { title: "公告信息" },
      },
      kaidajianshe: {
        menu: [
          { text: "地产开发", url: "/list?type=BdrQLqSNpbukcksXd" },
          { text: "工业厂房", url: "/list?type=uKm6eDiJoYrAZPGxD" },
          { text: "科技创新", url: "/list?type=FA8Xojtg4yTxsd7iN" },
          { text: "水利生态", url: "/list?type=n84tasiTD7tZQBhyo" },
          { text: "教育文旅", url: "/list?type=aGfjy38WcauxhSZiZ" },
          { text: "市政公用", url: "/list?type=LSMZ4ZP532ofzGhLN" },
          { text: "景观绿化", url: "/list?type=uSjku6koPfkKKHqAg" },
          { text: "其他项目", url: "/list?type=APpWvgSZmTHK7jre2" },
        ],
        dichankaifa: { title: "地产开发" },
        gongyechangfang: { title: "工业厂房" },
        kejichuangxin: { title: "科技创新" },
        shuilishengtai: { title: "水利生态" },
        jiaoyuwenlv: { title: "教育文旅" },
        shizhenggongyong: { title: "市政公用" },
        jingguanlvhua: { title: "景观绿化" },
        qitaxiangmu: { title: "其他项目" },
      },
      kaidazhaoshang: {
        menu: [
          { text: "新能源汽车产业园", url: "/list?type=8h9f8jod6TmGv7avi" },
          { text: "新技术产业园", url: "/list?type=bP4xpgw5GvN5Li8uB" },
          { text: "青年人才服务园", url: "/list?type=KYeQPFqhKjTJZFqgG" },
          { text: "人力资源产业园", url: "/list?type=gJywirRF6jgZBFMgc" },
          { text: "了解更多", url: "/list?type=XKWc77iMypXKFhgMg" },
        ],
        xinnengyuan: { title: "新能源汽车产业园" },
        xinjishu: { title: "新技术产业园" },
        qingnianrencai: { title: "青年人才服务园" },
        renliziyuan: { title: "人力资源产业园" },
        liaojiegengduo: { title: "了解更多" },
      },
      kaidazhaopin: {
        menu: [
          { text: "人才理念", url: "/recruit/rcln?type=rcln" },
          { text: "加入凯达", url: "/recruit/jrkd?type=jrkd" },
          { text: "创新凯达", url: "/recruit/cxkd?type=cxkd" },
          { text: "成长凯达", url: "/recruit/czkd?type=czkd" },
          { text: "幸福凯达", url: "/recruit/xfkd?type=xfkd" },
        ],
        rencailinian: { title: "人才理念" },
        jiarukaida: { title: "加入凯达" },
        chuangxinkaida: { title: "创新凯达" },
        chengzhangkaida: { title: "成长凯达" },
        xingfukaida: { title: "幸福凯达" },
      },
      dangjiangongzuo: {
        menu: [
          { text: "党建动态", url: "/list?type=YcY7PMyvthmQndhZY" },
          { text: "反腐倡廉", url: "/list?type=sXMW3G43NR6ZchSFL" },
          { text: "专题活动", url: "/list?type=6tsXCPh3t3r7i8KyK" },
        ],
        dangjiandongtai: { title: "党建动态" },
        fanfuchanglian: { title: "反腐倡廉" },
        zhuantihuodong: { title: "专题活动" },
      },
      youqinglianjie: {
        menu: [
          { text: "政府网站", url: "/links?type=hKk9rEqQFtNBxaZG8" },
          { text: "党建人社", url: "/links?type=rcwkwWN3q9zcEbTHk" },
          { text: "平台企业", url: "/links?type=Xzbq5SeJrnyd9zDhq" },
          { text: "建工企业", url: "/links?type=bhmaeNxJkccYvPLAs" },
          { text: "财经金融", url: "/links?type=h5KWSqALLfJcj6Shu" },
          { text: "咨询机构", url: "/links?type=PQM7LsbN8yuaD5PAn" },
          { text: "合作单位", url: "/links?type=wJcqjd54mDnBGRK5g" },
          { text: "合作高校", url: "/links?type=K5KA3fFujFGMiF8ds" },
        ],
        zhengfuwangzhan: { title: "政府网站" },
        dangjianrenshe: { title: "党建人社" },
        pingtaiqiye: { title: "平台企业" },
        jiangongqiye: { title: "建工企业" },
        caijingjinrong: { title: "财经金融" },
        zixunjigou: { title: "咨询机构" },
        hezuodanwei: { title: "合作单位" },
        hezuogaoxiao: { title: "合作高校" },
      },
      qita: {
        menu: [
          { text: "联系我们", url: "/pages/lxwm?type=lxwm" },
          { text: "网站声明", url: "/pages/wzsm?type=wzsm" },
          { text: "常见问题", url: "/pages/cjwt?type=cjwt" },
          { text: "留言板", url: "/pages/lyb?type=lyb" },
        ],
        lianxiwomen: { title: "联系我们" },
        wangzhanshengming: { title: "网站声明" },
        changjianwenti: { title: "常见问题" },
        liuyanban: { title: "留言板" },
      },
    };
  },
  created() {
    this.getCrumb();
  },
  methods: {
    getCrumb() {
      const type = this.$route.query.type;
      if (
        type == "qyjj" ||
        type == "dszzc" ||
        type == "zzjg" ||
        type == "qywh"
      ) {
        this.$emit("update:menu", this.zoujinkaida.menu);
      } else if (
        type == "86GiJyxgPxzp4p7xZ" ||
        type == "m6ZYzvEfAyAfmk8aL" ||
        type == "hkng3zuaD6tgRh8wi" ||
        type == "d9myMuK84ALafhXYd" ||
        type == "uRLG5PtCsXhyDq5tX"
      ) {
        this.$emit("update:menu", this.kaidazixun.menu);
      } else if (
        type == "BdrQLqSNpbukcksXd" ||
        type == "uKm6eDiJoYrAZPGxD" ||
        type == "FA8Xojtg4yTxsd7iN" ||
        type == "n84tasiTD7tZQBhyo" ||
        type == "aGfjy38WcauxhSZiZ" ||
        type == "LSMZ4ZP532ofzGhLN" ||
        type == "uSjku6koPfkKKHqAg" ||
        type == "APpWvgSZmTHK7jre2"
      ) {
        this.$emit("update:menu", this.kaidajianshe.menu);
      } else if (
        type == "8h9f8jod6TmGv7avi" ||
        type == "bP4xpgw5GvN5Li8uB" ||
        type == "KYeQPFqhKjTJZFqgG" ||
        type == "gJywirRF6jgZBFMgc" ||
        type == "XKWc77iMypXKFhgMg"
      ) {
        this.$emit("update:menu", this.kaidazhaoshang.menu);
      } else if (
        type == "rcln" ||
        type == "jrkd" ||
        type == "cxkd" ||
        type == "czkd" ||
        type == "xfkd"
      ) {
        this.$emit("update:menu", this.kaidazhaopin.menu);
      } else if (
        type == "YcY7PMyvthmQndhZY" ||
        type == "sXMW3G43NR6ZchSFL" ||
        type == "6tsXCPh3t3r7i8KyK"
      ) {
        this.$emit("update:menu", this.dangjiangongzuo.menu);
      } else if (
        type == "hKk9rEqQFtNBxaZG8" ||
        type == "rcwkwWN3q9zcEbTHk" ||
        type == "Xzbq5SeJrnyd9zDhq" ||
        type == "bhmaeNxJkccYvPLAs" ||
        type == "h5KWSqALLfJcj6Shu" ||
        type == "PQM7LsbN8yuaD5PAn" ||
        type == "wJcqjd54mDnBGRK5g" ||
        type == "K5KA3fFujFGMiF8ds"
      ) {
        this.$emit("update:menu", this.youqinglianjie.menu);
      } else if (
        type == "lxwm" ||
        type == "wzsm" ||
        type == "cjwt" ||
        type == "lyb"
      ) {
        this.$emit("update:menu", this.qita.menu);
      }
      if (type == "qyjj") {
        this.$emit("update:title", this.zoujinkaida.qiyejianjie.title);
      } else if (type == "dszzc") {
        this.$emit("update:title", this.zoujinkaida.dongshizhang.title);
      } else if (type == "zzjg") {
        this.$emit("update:title", this.zoujinkaida.zuzhijiegou.title);
      } else if (type == "qywh") {
        this.$emit("update:title", this.zoujinkaida.qiyewenhua.title);
      } else if (type == "86GiJyxgPxzp4p7xZ") {
        this.$emit("update:title", this.kaidazixun.huanqiukandian.title);
      } else if (type == "m6ZYzvEfAyAfmk8aL") {
        this.$emit("update:title", this.kaidazixun.jujiaoqikai.title);
      } else if (type == "hkng3zuaD6tgRh8wi") {
        this.$emit("update:title", this.kaidazixun.kaidadongtai.title);
      } else if (type == "d9myMuK84ALafhXYd") {
        this.$emit("update:title", this.kaidazixun.shipinzixun.title);
      } else if (type == "uRLG5PtCsXhyDq5tX") {
        this.$emit("update:title", this.kaidazixun.gonggaoxinxi.title);
      } else if (type == "BdrQLqSNpbukcksXd") {
        this.$emit("update:title", this.kaidajianshe.dichankaifa.title);
      } else if (type == "uKm6eDiJoYrAZPGxD") {
        this.$emit("update:title", this.kaidajianshe.gongyechangfang.title);
      } else if (type == "FA8Xojtg4yTxsd7iN") {
        this.$emit("update:title", this.kaidajianshe.kejichuangxin.title);
      } else if (type == "n84tasiTD7tZQBhyo") {
        this.$emit("update:title", this.kaidajianshe.shuilishengtai.title);
      } else if (type == "aGfjy38WcauxhSZiZ") {
        this.$emit("update:title", this.kaidajianshe.jiaoyuwenlv.title);
      } else if (type == "LSMZ4ZP532ofzGhLN") {
        this.$emit("update:title", this.kaidajianshe.shizhenggongyong.title);
      } else if (type == "uSjku6koPfkKKHqAg") {
        this.$emit("update:title", this.kaidajianshe.jingguanlvhua.title);
      } else if (type == "APpWvgSZmTHK7jre2") {
        this.$emit("update:title", this.kaidajianshe.qitaxiangmu.title);
      } else if (type == "8h9f8jod6TmGv7avi") {
        this.$emit("update:title", this.kaidazhaoshang.xinnengyuan.title);
      } else if (type == "bP4xpgw5GvN5Li8uB") {
        this.$emit("update:title", this.kaidazhaoshang.xinjishu.title);
      } else if (type == "KYeQPFqhKjTJZFqgG") {
        this.$emit("update:title", this.kaidazhaoshang.qingnianrencai.title);
      } else if (type == "gJywirRF6jgZBFMgc") {
        this.$emit("update:title", this.kaidazhaoshang.renliziyuan.title);
      } else if (type == "XKWc77iMypXKFhgMg") {
        this.$emit("update:title", this.kaidazhaoshang.liaojiegengduo.title);
      } else if (type == "rcln") {
        this.$emit("update:title", this.kaidazhaopin.rencailinian.title);
      } else if (type == "jrkd") {
        this.$emit("update:title", this.kaidazhaopin.jiarukaida.title);
      } else if (type == "cxkd") {
        this.$emit("update:title", this.kaidazhaopin.chuangxinkaida.title);
      } else if (type == "czkd") {
        this.$emit("update:title", this.kaidazhaopin.chengzhangkaida.title);
      } else if (type == "xfkd") {
        this.$emit("update:title", this.kaidazhaopin.xingfukaida.title);
      } else if (type == "YcY7PMyvthmQndhZY") {
        this.$emit("update:title", this.dangjiangongzuo.dangjiandongtai.title);
      } else if (type == "sXMW3G43NR6ZchSFL") {
        this.$emit("update:title", this.dangjiangongzuo.fanfuchanglian.title);
      } else if (type == "6tsXCPh3t3r7i8KyK") {
        this.$emit("update:title", this.dangjiangongzuo.zhuantihuodong.title);
      } else if (type == "hKk9rEqQFtNBxaZG8") {
        this.$emit("update:title", this.youqinglianjie.zhengfuwangzhan.title);
      } else if (type == "rcwkwWN3q9zcEbTHk") {
        this.$emit("update:title", this.youqinglianjie.dangjianrenshe.title);
      } else if (type == "Xzbq5SeJrnyd9zDhq") {
        this.$emit("update:title", this.youqinglianjie.pingtaiqiye.title);
      } else if (type == "bhmaeNxJkccYvPLAs") {
        this.$emit("update:title", this.youqinglianjie.jiangongqiye.title);
      } else if (type == "h5KWSqALLfJcj6Shu") {
        this.$emit("update:title", this.youqinglianjie.caijingjinrong.title);
      } else if (type == "PQM7LsbN8yuaD5PAn") {
        this.$emit("update:title", this.youqinglianjie.zixunjigou.title);
      } else if (type == "wJcqjd54mDnBGRK5g") {
        this.$emit("update:title", this.youqinglianjie.hezuodanwei.title);
      } else if (type == "K5KA3fFujFGMiF8ds") {
        this.$emit("update:title", this.youqinglianjie.hezuogaoxiao.title);
      } else if (type == "lxwm") {
        this.$emit("update:title", this.qita.lianxiwomen.title);
      } else if (type == "wzsm") {
        this.$emit("update:title", this.qita.wangzhanshengming.title);
      } else if (type == "cjwt") {
        this.$emit("update:title", this.qita.changjianwenti.title);
      } else if (type == "lyb") {
        this.$emit("update:title", this.qita.liuyanban.title);
      }
    },
    go(url) {
      window.open(url, "_self");
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    menu: {
      type: Array,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.crumb {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
  margin-top: 30px;
  .crumb-title {
    font-size: 28px;
    line-height: 40px;
    font-weight: normal;
  }
  .crumb-menu {
    display: flex;
    background: #f7f7f7;
    padding: 0 20px;
    li {
      margin: 0 25px;
    }
    .router-link-exact-active {
      color: #cf281a;
    }
  }
}
</style>
