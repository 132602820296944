<template>
  <div class="wrapper">
    <Crumb :title.sync="title" :menu.sync="menu" />
    <Banner :img.sync="img" />
    <div class="container">
      <div class="content">
        <div class="title">
          <span>04</span>
          <h3>成长凯达</h3>
          <p>Training</p>
        </div>
        <p>
          <b class="red"
            >凯达星火计划：确保人才“量”的充足和“质”的优良，为企业培养可靠接班人，确保事业薪火相传。</b
          >
        </p>
        <p>
          凯达公司坚持以“在引上做文章，在留上下功夫，在育上求突破，在用上见实效”为核心思路，面向国内双一流高校，招聘优秀毕业生实行轮岗培养，提供多岗位实践锻炼机会，充分发挥员工特长，以培养行业专业技能为核心、跨业务掌握多项技能为辅助，变“一专”为“多能”，变“专才”为“通才”，培养一职多岗、一专多能、多专多能、精于专、胜于能的复合型人才，使凯达人个个成为“懂经营、善管理、通财务、精专业”的优秀职业经理人。
        </p>
        <p>
          <b class="red"
            >凯达新星计划：激励人才增强自我完善意识，培养能力卓越、榜样作用显著的“凯达新星”。</b
          >
        </p>
        <p>
          凯达公司在人才建设方面建立“点、线、面”立体化培养体系，广泛引进、集聚行业内科技领军人才、运营管理人才、高技能人才和高水平创新团队，建立引进人才常态化工作机制，持续实施经营领军人才和中青年人才专项培养计划，加大关键人才中长期激励力度。通过广罗人才、建言献策，发挥决策咨询和参谋作用，推动经济效益、技术及管理水平持续提高，培养能力卓越、榜样作用显著的“凯达新星”，为区域经济高质量发展提供人才保障。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Crumb from "@/components/Crumb.vue";
import Banner from "@/components/Banner.vue";
export default {
  components: { Crumb, Banner },
  data() {
    return {
      title: "",
      menu: [],
      img: require("@/assets/images/banner3.jpg"),
    };
  },
};
</script>

<style scoped lang="less">
.wrapper {
  .container {
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    padding: 30px 0;
    .content {
      color: #666;
      .title {
        background: url(../../assets/images/bg-title.png) no-repeat 0 0;
        width: 255px;
        height: 50px;
        color: #fff;
        margin-bottom: 20px;
        padding: 15px 20px;
        span {
          display: block;
          float: left;
          width: 50px;
          height: 50px;
          font-size: 24px;
          color: #eb2737;
          text-align: center;
          font-weight: bold;
          line-height: 50px;
          margin-right: 10px;
        }
        h3 {
          font-size: 20px;
          line-height: 30px;
          font-weight: normal;
        }
        p {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 0;
        }
      }
      p {
        margin-bottom: 1em;
      }
      .red {
        color: #eb2737;
      }
      .align-right {
        text-align: right;
      }
    }
  }
}
</style>
