<template>
  <div class="banner" v-if="img">
    <img :src="img" />
  </div>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {};
  },
  props: {
    img: {
      type: String,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.banner {
  margin-top: 30px;
}
</style>
